@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";

#root {
  @apply h-screen;
}

canvas {
  // background-color: #ebeceb !important;
}

.studio-main-container {
  background-color: #efeff0 !important;
  min-height: 65vh !important;
  max-height: 85vh !important;
  overflow: auto !important;
  margin-top: 30px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.studio-container {
  margin: auto !important;
  position: absolute !important;
  top: 0% !important;
  left: 0% !important;
  transform: translate(0%, 0%) !important;

}

.chrome-picker {
  box-shadow: none !important;
  font-family: inherit !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(138, 138, 138, 0.221);
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: var(--primary-gradient); */
  background: rgb(135, 135, 135);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(222, 222, 222);
}

:root {
  --swiper-navigation-size: 12px;
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-color: #fff;
}

/* Chrome, Safari, Edge, Opera */
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-number {
  -moz-appearance: textfield;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently                                 supported by Chrome, Edge, Opera and Firefox */
}

.properties-animation-slider {

  .MuiSlider-thumb,
  .MuiSlider-track {
    transition: none !important;
  }
}

.css-a5rdam-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px;
}

.properties-autocomplete-tiny {
  .MuiAutocomplete-input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

// CUSTOM STYLE ADDED BILL
.SearchItem {
  position: relative;
  margin-bottom: 10px;

  input {
    border-radius: 4px;
    padding: 5px;
    width: 100%;
    padding-left: 32px;
    font-size: 14px;
  }

  button {
    position: absolute;
    left: 0;
    font-size: 14px;
  }
}

// Header STYLE
#header-element {
  padding: 10px 5px;
  background-color: #0177b5 !important;
  z-index: 99999 !important;
}

.iconbtn {
  color: #fff !important;
}

.icon_btn {
  background-color: #ff7700 !important;
  border-color: #e56c01 !important;
  color: #fff !important;
  transition: 0.3s all ease-in;
}

.icon_btn:hover {
  color: #a02828 !important;
  background-color: #fff !important;
  transition: 0.3s all ease-in;
}

.togglebtn {
  color: #fff !important;
  margin-left: 10px !important;
  font-size: 24px !important;
  font-weight: normal !important;
}

// TOGGLE SIDEBAR style
#ul-side {
  // background-color: #3A7DD9 !important;
  // background-color: #1f2937;
  background-color: #0077b5;
}

#edit_ToolStyle li {
  color: #fff !important;
}

#side-contect-wrap {
  position: relative;
  // width: 100%;

  .icon-arrow {
    top: 0px;
    background-color: #a5a3a3;
    position: absolute;
    right: 0px;

    button {
      font-size: 14px;

      svg {
        color: #fff;
      }
    }
  }
}

.itemName {
  word-break: break-all;
  margin-right: 0.5rem;
  padding-left: 0rem;
}

.SearchItem {
  margin-top: 8px;
  z-index: 999;
}

.temp-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

form.searchForm {
  width: 100%;
}

.temp-box {
  width: 48%;
}

.EditTools_menuListItemActive__5STro {
  background-color: #a5a3a3 !important;
}

#side-contect-wrap {
  // background-color: #a5a3a3 !important;
  background-color: #fff !important;

}

// FOR CANVAS SETTING
#studio-element {
  display: flex;
  align-items: center;
}

.MuiImageList-root .MuiImageListItem-root .MuiImageListItem-img {
  border-radius: 10px !important;
}

// EDITOR MEDIA QUERY
@media only screen and (max-width: 991px) {
  #studio-element {
    display: none;
    position: absolute;
    right: 0px;
    top: 50px;
  }

  .konvajs-content {
    width: 50% !important;
    height: auto !important;

    canvas {
      width: 100% !important;
      height: auto !important;
      //position: unset !important;
    }
  }

  .css-1940n67 {
    width: 100% !important;
    max-width: 300px !important;
  }

  #style-settings-element {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  #header-element {
    button {
      border: none;
    }

    .mbl-hide {
      display: none;
    }

    svg {
      height: 18px !important;
      width: 18px !important;
    }
  }

  .Header_header__r-Yxv {
    height: auto !important;
  }

  // .css-gpoj7t-MuiGrid-root{
  //   flex-basis: auto !important;
  // }
  .css-ae25l1-MuiGrid-root {
    justify-content: flex-start !important;
  }

  #edit-tools-element {
    display: none;
  }

  #style-settings-element {
    position: absolute;
    top: 80px;
    right: 0px;
  }
}

.form-check-input:checked {
  background-color: green;
  /* Change this to your desired color */
}

.my-color {
  &:hover {
    color: white;
  }
}

.hover-icon:hover {
  color: "#fff";
  /* Set your desired color for the hover state */
}

.bg-blue-500 {
  background-color: "red";
}

.bg-blue-600 {
  background-color: "red";
}

.bg-blue-700 {
  background-color: "red";
}

.drag-section {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ededed;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.drag-section svg {
  color: #0077b5 !important;
  border-right: 1px solid #ededed;
  padding-right: 5px;
  margin-right: 5px;
  height: 20px;
}

.drag-section:hover {
  border: 1px solid #ff5722 !important;
}

.deleteicon svg {
  border-right: 0px;
  padding: 0;
  margin: 0;
  width: 16px;
}

#header-element {
  background-color: #fff !important;
}

#header-element svg {
  color: #0177b5 !important;
}

#header-element .icon_btn svg {
  color: #fff !important;
}

#studio-element {
  background-color: #e6e9ed;
}

.icon_btn:hover {
  background-color: #cc5f00 !important;
  border-color: #a84f01 !important;
  color: #fff !important;
}

.deleteicon {
  padding-right: 0px !important;
}