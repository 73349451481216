.sidebar {
  @apply w-80 shadow-xl relative;
}

.default {
  @apply h-full flex flex-col items-center justify-center;

  &Item {
    @apply text-center px-16 mb-12 last:mb-0;

    &Image {
      @apply block m-auto mb-3;
    }

    &Title {
      @apply block font-semibold mb-3;
    }

    &Hint {
      @apply text-xs text-gray-500;
    }
  }
}
