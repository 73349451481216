.sidebar {
  @apply flex;
}

.content {
  @apply w-96 bg-gray-800;
}

.menu {
  @apply w-20 bg-gray-900 relative;

  &List {
    @apply list-none w-full py-2 m-0;

    &Item {
      @apply text-center py-3 m-0 cursor-pointer hover:text-gray-100 text-gray-500;

      &Active {
        @apply bg-gray-800 text-gray-100;
      }

      &Icon {
        @apply m-auto;
      }

      &Text {
        @apply text-xs;
      }
    }
  }
}
