.main {
  @apply flex h-full;

  &ContentWrapper {
    @apply flex flex-col flex-1;
  }

  &Content {
    @apply flex h-full;
  }
}
